import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import VueGtag from "vue-gtag";
import VueMatomo from "vue-matomo";

import VueSocialSharing from "vue-social-sharing";
// import "@/scss/v2/fontello/css/fontello.css";
Vue.use(VueSocialSharing);

import IconBase from "@/components/icons/IconBase";
import IconHeater from "@/components/icons/IconHeater";
import IconDhw from "@/components/icons/IconDhw";
import IconVentilation from "@/components/icons/IconVentilation";
import IconWall from "@/components/icons/IconWall";
import IconRoof from "@/components/icons/IconRoof";
import IconFloor from "@/components/icons/IconFloor";
import IconOpening from "@/components/icons/IconOpening";
import IconAirtightness from "@/components/icons/IconAirtightness";
import IconProgrammation from "@/components/icons/IconProgrammation";
import IconLogoHouse from "@/components/icons/IconLogoHouse";
import IconLogoHouseDeg from "@/components/icons/IconLogoHouseDeg";
import IconLogoCerclePromodul from "@/components/icons/IconLogoCerclePromodul";
import IconLogoLeLab from "@/components/icons/IconLogoLeLab";

import LevelsLegend from "@/components/v2/LevelsLegend";
import Modal from "@/components/v2/Modal";
import DebugV2 from "@/components/v2/Debug";
import LotSelectedReminder from "@/components/v2/LotSelectedReminder";
import SocialNetwork from "@/components/v2/SocialNetwork";

import "bootstrap-icons/font/bootstrap-icons.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "@/scss/v2/app.scss";

Vue.component("icon-base", IconBase);
Vue.component("icon-heater", IconHeater);
Vue.component("icon-dhw", IconDhw);
Vue.component("icon-ventilation", IconVentilation);
Vue.component("icon-wall", IconWall);
Vue.component("icon-roof", IconRoof);
Vue.component("icon-floor", IconFloor);
Vue.component("icon-opening", IconOpening);
Vue.component("icon-airtightness", IconAirtightness);
Vue.component("icon-programmation", IconProgrammation);
Vue.component("icon-logo-house", IconLogoHouse);
Vue.component("icon-logo-house-deg", IconLogoHouseDeg);

Vue.component("icon-logo-cercle-promodul", IconLogoCerclePromodul);
Vue.component("icon-logo-le-lab", IconLogoLeLab);

Vue.component("debug-v2", DebugV2);
Vue.component("lot-selected-reminder", LotSelectedReminder);
Vue.component("modal", Modal);
Vue.component("levels-legend", LevelsLegend);
Vue.component("social-network", SocialNetwork);

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "RénoVigilance";
  next();
});

Vue.use(
  VueGtag,
  {
    config: { id: "G-QJBL85MDEC" },
    disableScriptLoad: process.env.VUE_APP_ENV !== "production",
  },
  router
);

if (process.env.VUE_APP_ENV === "production") {
  Vue.use(VueMatomo, {
    // Configure your matomo server and site by providin
    host: "https://matomo.inef4.org",
    siteId: 8,
    router: router,
    enableLinkTracking: true,
    // enableLinkTracking: true,
  });
}

Vue.config.productionTip = false;

/** DIRECTIVES **/
Vue.directive("click-outside", {
  bind: function(el, binding, vnode) {
    el.eventSetDrag = function() {
      el.setAttribute("data-dragging", "yes");
    };
    el.eventClearDrag = function() {
      el.removeAttribute("data-dragging");
    };
    el.eventOnClick = function(event) {
      var dragging = el.getAttribute("data-dragging");
      // Check that the click was outside the el and its children, and wasn't a drag
      if (!(el == event.target || el.contains(event.target)) && !dragging) {
        // call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.addEventListener("touchstart", el.eventClearDrag);
    document.addEventListener("touchmove", el.eventSetDrag);
    document.addEventListener("click", el.eventOnClick);
    document.addEventListener("touchend", el.eventOnClick);
  },
  unbind: function(el) {
    document.removeEventListener("touchstart", el.eventClearDrag);
    document.removeEventListener("touchmove", el.eventSetDrag);
    document.removeEventListener("click", el.eventOnClick);
    document.removeEventListener("touchend", el.eventOnClick);
    el.removeAttribute("data-dragging");
  },
});
/** /DIRECTIVES **/

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
