<template>
  <div class="main-wrapper">

    <!-- <btn-steps :currentStep="currentStep" :maxStep="maxStep" @goToPrevStep="goToPrevStep" @goToNextStep="goToNextStep">
    {{currentStepTitle}}
  </btn-steps> -->


    <section v-if="currentStep > 1">
      <div class="link-go-back" @click="goToPrevStep">Etape précedente</div>
      <lot-selected-reminder @goToStep="goToStep"></lot-selected-reminder>
    </section>

    <transition :name="effectSelected" mode="out-in">
      <div :class="mainContentAnimation" class="main-content" :key="currentStep">

        <!-- currentStep: {{currentStep}} / currentStepIsValid : {{currentStepIsValid}} -->

        <div v-if="currentStepData">
          <!-- <h3>{{currentStepData.title}}</h3> -->
          <section ref="step1" v-if="currentStep === 1">
            <step-1 :stepId="1" :stepTitle="currentStepData.title" :lotTvxUsed="currentStepData.lotTvxUsed" @goToNextStep="goToNextStep"></step-1>
          </section>
          <section ref="step2" v-if="currentStep === 2">
            <step-2 :stepId="2" :stepTitle="currentStepData.title" :lotTvxUsed="currentStepData.lotTvxUsed" @goToNextStep="goToNextStep"></step-2>
          </section>
          <section ref="step3" v-if="currentStep === 3">

            <step-3 :stepId="3" :stepTitle="currentStepData.title" :lotTvxUsed="currentStepData.lotTvxUsed"></step-3>
            <div class="link-go-top" @click="scrollTop">Retour vers le haut</div>
          </section>
        </div>
        <div v-else>
          <section>
            <div class="alert alert-info p-5">
              <h4>Page introuvable !</h4>
              <router-link :to="{ name: 'v2' }">Aller sur la page d'accueil</router-link>
            </div>
          </section>
        </div>
      </div>
    </transition>

    <!-- <btn-steps :currentStep="currentStep" :maxStep="maxStep" @goToPrevStep="goToPrevStep" @goToNextStep="goToNextStep"></btn-steps> -->

    <!-- <debug-v2 title="lotsTvxA" :data="lotsTvx.A" /> -->
    <!-- <hr>

  <debug-v2 title="lotsTvxA" :data="lotsTvx.A" />
  <debug-v2 title="lotsTvxB" :data="lotsTvx.B" />
  <debug-v2 title="tvxFamilies" :data="tvxFamilies" />
  <debug-v2 title="steps" :data="steps" /> -->

  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  // import StepMaster from '@/components/v2/StepMaster.vue'
  import Step1 from '@/components/v2/Step1.vue';
  import Step2 from '@/components/v2/Step2.vue';
  import Step3 from '@/components/v2/Step3.vue';
  // import BtnSteps from '@/components/v2/BtnSteps.vue'

  // import Debug from '@/components/v2/Debug.vue' 
  export default {
    components: {
      // Debug,
      // StepMaster,
      Step1,
      Step2,
      Step3,
      // BtnSteps
    },
    data() {
      return {
        effectSelected: 'component-fade',
        effectList: ['bounce', 'component-fade', 'slide-fade'],
        // maxStep: 3,
        mainContentAnimation: null
      };
    },
    // beforeCreate() {
    //   console.log('beforeCreate')
    // },
    created() {
      // console.log('created')
      this.checkCurrentStep();
      this.init();
      this.checkStateData();
    },
    // beforeMount () {
    //   console.log('beforeMount')
    // },
    mounted() {
      // console.log('mounted')
      window.scrollTo(0, 0);
    },
    // beforeUpdate () {
    //   console.log('beforeUpdate')
    // },
    computed: {
      ...mapState(['steps', 'lotsTvx', 'lotDefault']),
      ...mapGetters(['tvxFamilies']),
      currentStep() {
        return parseInt(this.$route.params.step) ?? 1;
      },
      currentStepData() {
        return this.steps.find((step) => {
          return step.id === this.currentStep;
        });
      },
      currentStepIsValid() {
        return this.currentStepData?.isValid;
      },
      currentStepTitle() {
        return this.currentStepData?.title;
      },
      maxStep() {
        return this.steps.length;
      }
    },
    methods: {
      init() {
        console.log('%c Init !', 'color: green; font-weight: bold');
        // this.checkCurrentStep()
        this.lotsTvx.A = { ...this.lotDefault };
        this.lotsTvx.B = { ...this.lotDefault };

        // console.log(this.lotsTvx.A)
      },
      checkStateData() {

        if (this.currentStep > 1 && this.lotsTvx.A.familyData === null) {
          console.log('%c Data Error... Redirection...', 'color: red; font-weight: bold');
          this.$router.replace({ name: 'v2' });
        }
      },
      checkCurrentStep() {
        if (this.currentStep < 1 || this.currentStep > this.maxStep) {
          console.log('%c Step ' + this.currentStep + ' incorrect... Redirection...', 'color: red; font-weight: bold');
          this.$router.replace({ name: 'v2' });
        }
      },
      goToStep(stepNum) {
        // console.log(this.currentStep, stepNum)
        if (this.currentStep.toString() === stepNum.toString()) {
          console.log('%c Step ' + stepNum + ' is the currentStep', 'color: red; font-weight: bold');
          return false;
        } else {
          console.log(`%c Go to Step... ${stepNum}...`, 'color: aqua; font-weight: bold');
          this.$router.push({ name: 'stepV2', params: { step: stepNum } });
        }

        // this.$refs['step' + stepNum].$el.scrollIntoView({ block: 'end', scrollBehavior: 'smooth' });
      },
      goToPrevStep() {
        if (this.currentStep === 0) return;
        this.goToStep(this.currentStep - 1);
      },
      goToNextStep() {
        // alert('rrrr')
        if (this.currentStep > this.maxStep) return;
        if (!this.currentStepIsValid) {
          this.setMainContentAnimation('animate-wrong');
          return;
        }
        this.goToStep(this.currentStep + 1);
      },
      scrollTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      setMainContentAnimation(animationName) {
        this.mainContentAnimation = animationName;
        setTimeout(() => {
          this.mainContentAnimation = null;
        }, 600);
      }//,
      // stepValidation () {
      //   const valid = true
      //   if (valid) 
      //     this.mainContentAnimation = "animate-out";
      //   if (!valid) {
      //     this.mainContentAnimation = "animate-wrong";
      //     setTimeout(() => {
      //       this.mainContentAnimation = "";
      //     }, 400);
      //   }
      // }
    },
    watch: {
      currentStep() {
        // console.log('Watch currentStep')
        this.checkCurrentStep();
      },
      'lotsTvx.A.familyData': {
        handler() {
          this.$store.dispatch('saveSystemLot', { lot: 'A', value: { ...this.lotDefault.systemData } });
        },
        deep: true
      }

    }

  };
</script>