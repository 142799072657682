<template>
  <div>

    <div class="filters">


      <div v-if="filtersList.family.length > 0">
        <div class="section-bloc info-alert">
          <i class="fas fa-exclamation-triangle"></i>
          <p>
            Les travaux accomplis sur ce lot impactent les lots suivants. Pour chacun d'eux il est précisé une échelle du niveau de vigilance à adopter et des précautions à respecter.

            <!-- <i class="fas fa-question-circle help-btn" @click="showModalLegend = !showModalLegend"></i> -->
            <i class="fas fa-info-circle help-btn" @click="showModalLegend = !showModalLegend"></i>
          </p>
        </div>

        <div>
          <!-- <h3 ref="filterSystem">Découvrez les différents points de vigilance listés pour chaque lot : <i class="far fa-question-circle help-btn" @click="showModalLegend = !showModalLegend"></i></h3> -->
          <div class="section-bloc filter-item">
            <!-- ICI PICTO -->
            <div class="lots-block">
              <div class="lot-item" :class="{ 'active': filtersSelected.family && family.id === filtersSelected.family }" v-for="(family, familyId) in filtersList.family" :key="familyId"
                @click="setFilter('family', family.id)">
                <complexity-level :level="family.complexityMax"></complexity-level>
                <!-- <span class="badge badge-counter">{{getVpCount(family.id)}}</span> -->
                <lot-tvx :name="family.name" :icon="family.icon"></lot-tvx>
              </div>
            </div>
            <!-- /ICI PICTO -->
          </div>
        </div>
      </div>

      <template v-if="filtersSelected.family && filtersList.system.length > 1">

        <div class="section-bloc filter-item">
          <div class="form-block form-select">
            <!-- <label for="lotTvxB.system">Système</label> -->
            <label ref="filterSystem">Préciser :</label>
            <select id="lotTvxB.system" v-model="filtersSelected.system">
              <!-- <option :value="null">Tous</option> -->
              <option :value="null" v-if="filtersList.system.length > 1">Sélection...</option>
              <option :value="fltr.idCombi" v-for="fltr in filtersList.system" :key="fltr.id">{{ fltr.name }}</option>
            </select>
          </div>
        </div>
      </template>

      <!-- <template>
            <h1>TEST</h1>
            <system-data-select-2 :lot-data="lotTvxData" type-lot="following"></system-data-select-2>
        </template> -->




    </div>

    <div v-if="!showVigilancePoints">
      <div class="section-bloc">
        <center><small>Veuillez effectuer une sélection ci-dessus.</small></center>
      </div>
    </div>
    <div v-else>
      <h3 ref="results">{{ titleNumbersPv }}</h3>
      <div class="section-bloc pv-results">

        <p class="info">Retrouvez ci-dessous le niveau d'alerte et de précautions à adopter ainsi que les recommandations, bonnes pratiques et mesures conservatoires pour y répondre.</p>

        <transition :name="effectSelected" mode="out-in">
          <div :key="randomKeyForTansition">
            <div v-if="!vigilancePointsListFilteredWithComplexity.length" class="vp-item no-item">Aucun élement trouvé...</div>
            <div v-for="(vp, vpIdx) in vigilancePointsListFilteredWithComplexity" :key="vpIdx" class="vp-item" :class="'level' + vp.complexity">
              <!-- <div>{{vp.complexity}}</div> -->
              <lot-tvx :name="vp.familyData.name" :icon="vp.familyData.icon"></lot-tvx>
              <div class="vp-item-body">
                <div class="vp-system">{{ vp.familyData.name }} {{ vp.systemData ? ' : ' + vp.systemData.label : null }}</div>
                <div class="vp-title">{{ vp.title }}</div>
                <div class="vp-description">{{ vp.description }}</div>
                <small v-if="isDebug">DEBUG >> id: {{ vp.id }} / condition.id: {{ vp.condition.id }} / priority: {{ vp.priority }} / complexity: {{ vp.complexity }}</small>
                <small v-if="isDebug">
                  <pre>{{ vp.condition }}</pre>
                  <pre>{{ vp.systemData }}</pre>
                </small>
              </div>

            </div>

          </div>
        </transition>
        <levels-legend></levels-legend>
      </div>
    </div>

    <modal v-if="showModalLegend" @close="showModalLegend = false">
      <!-- <h3 slot="header"></h3> -->
      <div slot="body">
        <levels-legend></levels-legend>
      </div>
    </modal>




    <button @click="isDebug = !isDebug" v-if="vueAppEnv !== 'production'">DEBUG</button>
    <!-- <button @click="isUniqueVp = !isUniqueVp">isUniqueVp</button> -->

    <template v-if="isDebug">
      <debug-v2 title="filtersSelected" :data="filtersSelected"></debug-v2>
      <debug-v2 title="filtersList.system" :data="filtersList.system"></debug-v2>
      <debug-v2 title="filtersList.family" :data="filtersList.family"></debug-v2>
      <debug-v2 title="lotTvxData" :data="lotTvxData"></debug-v2>
      <debug-v2 title="vigilancePointsList" :data="vigilancePointsList"></debug-v2>
      <!-- <debug-v2 title="vigilanceConditionAttrs" :data="vigilanceConditionAttrs"></debug-v2> -->

      <debug-v2 title="vigilancePointsListFiltered" :data="vigilancePointsListFiltered"></debug-v2>
    </template>

  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import LotTvx from '@/components/v2/LotTvx.vue';
  import ComplexityLevel from '@/components/v2/ComplexityLevel.vue';
  import { matomoTrackEvent } from '@/utils/matomoTracker.js';
  export default {
    props: {
      lotTvxUsed: {
        required: true
      },
      stepId: {
        required: true
      },
      stepTitle: {
        required: false
      }
    },
    data() {
      return {
        isDebug: false,
        showModal: false,
        showModalLegend: false,
        isUniqueVp: false,
        randomKeyForTansition: 0,
        filtersSelected: {
          family: null,
          system: null,
          complexity: null
        }
      };
    },
    components: {
      LotTvx,
      ComplexityLevel
    },
    beforeUpdate() {
      // console.log("UPDATE")
    },
    beforeMount() {
      // console.log("MOUNT")
    },
    created() {
      // console.log("CREATED")
      if (this.filtersList.family.length === 1) {
        this.filtersSelected.family = this.filtersList.family[0].id;
      }
    },
    computed: {
      ...mapState(['lotsTvx', 'effectSelected']),
      ...mapGetters(
        [
          // 'tvxFamilies',
          'tvxSystemByDbSrc',
          'vigilancePointForPrecedingLot'
        ]
      ),
      vueAppEnv() {
        return process.env.VUE_APP_ENV;
      },
      showVigilancePoints() {
        return (this.filtersSelected.family && this.filtersList.system.length === 0) ||
          (this.filtersSelected.family && this.filtersList.system.length >= 1 && this.filtersSelected.system !== null);
      },
      lotTvxData() {
        if (!this.lotsTvx[this.lotTvxUsed]) {
          console.error(`LotTvx ${this.lotTvxUsed} unknow`);
          return null;
        }
        return this.lotsTvx[this.lotTvxUsed];
      },
      vigilanceConditionAttrs() {
        if (!this.lotTvxData?.familyData?.vigilanceCondition) return;
        return this.lotTvxData?.familyData?.vigilanceCondition;
      },
      systemData() {
        return this.lotTvxData?.systemData;
      },
      vigilancePointsListCounter() {
        const vpcounter = {};
        this.vigilancePointsList.forEach((item) => {
          let familyB = item.family;
          // console.log(`familyB:  ${familyB} / ${item.systemData?.label}`)
          if (!vpcounter[familyB]) vpcounter[familyB] = 0;
          vpcounter[familyB]++;
        });
        return vpcounter;
      },
      vigilancePointsList() {
        // console.log('vigilancePointsList vigilanceConditionAttrs', this.vigilanceConditionAttrs)
        // console.log('vigilancePointsList systemData', this.lotTvxData?.systemData)

        const vps = this.vigilancePointForPrecedingLot({
          preceding_lot_type: this.vigilanceConditionAttrs?.family,
          preceding_lot_attr: this.vigilanceConditionAttrs?.attr,
          preceding_lot_value_before: this.systemData.beforeTvx?.id,
          preceding_lot_value_after: this.systemData.afterTvx?.id,
          is_unique: this.isUniqueVp
        });

        // console.log('TEST', test.systemData)
        vps.sort((a, b) => (a.systemData?.available > b.systemData?.available) ? 1 : ((a.systemData?.available > b.systemData?.available) ? -1 : 0));

        return vps;
      },
      vigilancePointsListFiltered() {
        this.setRandomKeyForTansition();
        const originalList = [...this.vigilancePointsList];
        let newList = [];
        if (this.filtersSelected.family) {
          newList = originalList.filter((itm) => {
            return itm.family === this.filtersSelected.family;
          });
          if (this.filtersSelected.system) {
            newList = newList.filter((itm) => {
              // const idCombi = itm.systemData.family + '-' + itm.systemData.id

              //if !itm.systemData, VP is shown all times
              return !itm.systemData || itm.systemData?.idCombi === this.filtersSelected.system;
            });
          }
        } else {
          newList = originalList;
        }

        return newList;
      },
      vigilancePointsListFilteredWithComplexity() {
        this.setRandomKeyForTansition();
        if (this.filtersSelected.complexity !== null) {
          return this.vigilancePointsListFiltered.filter((itm) => {
            return itm.complexity === this.filtersSelected.complexity;
          });
        }

        const unique = [];
        this.vigilancePointsListFiltered.forEach((itm) => {
          var i = unique.findIndex(x => x.id == itm.id);
          if (i <= -1) {
            unique.push(itm);
          }
        });
        // console.log('vigilancePointsListFilteredWithComplexity', unique)
        return unique;
        // return this.vigilancePointsListFiltered
      },
      // nbVpByComplexity () {
      //   let counts = {}
      //   for (let i = 0; i < this.vigilancePointsListFiltered.length; i++) {
      //     let level = this.vigilancePointsListFiltered[i].complexity;
      //     counts[level] = counts[level] ? counts[level] + 1 : 1;
      //   }
      //   return counts
      // },
      titleNumbersPv() {
        const nb = this.vigilancePointsListFilteredWithComplexity.length;
        const wordSingle = 'point';
        const wordPlural = wordSingle + 's';
        const wordUsed = nb > 1 ? wordPlural : wordSingle;
        return `${nb} ${wordUsed}  de vigilance`;
      },

      filtersList() {
        // console.log('---------- filtersList ----------')
        let filters = { family: [], system: [], complexity: [] };
        let history = { family: [], system: [], complexity: [] };
        // this.vigilancePointsListFiltered.

        this.vigilancePointsList.forEach(vp => {

          if (history.family.indexOf(vp.family) === -1) {
            filters.family.push({
              id: vp.family,
              name: vp.familyData.name,
              icon: vp.familyData.icon,
              priorityMax: Math.max.apply(Math, this.vigilancePointsList.map(function (itm) {
                return vp.family == itm.family ? itm.priority : null;
              })),
              complexityMax: Math.max.apply(Math, this.vigilancePointsList.map(function (itm) {
                return vp.family == itm.family ? itm.complexity : null;
              })),
              nb: 0
            });
            history.family.push(vp.family);
          }



          if (vp.family /*&& vp.systemData?.id */ && this.filtersSelected.family === vp.family) {
            const available = vp.systemData?.available;
            if (history.system.indexOf(vp.systemData?.idCombi) === -1 && vp.systemData) {
              let prefix = available ? '(Futur) ' : '(Existant) ';
              let label = vp.systemData?.label;
              let name = vp.familyData?.typeLot === 'system' ? prefix + label : label;

              filters.system.push({
                id: vp.systemData?.id,
                family: vp.systemData?.family,
                idCombi: vp.systemData?.idCombi,
                name: name,
                available: available
              });
              history.system.push(vp.systemData?.idCombi);

            }
          }
        });

        return filters;
      }
    },
    methods: {
      setRandomKeyForTansition() {
        this.randomKeyForTansition = Math.round(Math.random() * 1000);
      },
      setFilter(type, data) {
        this.filtersSelected[type] = data;
        const familyItem = this.filtersList.family.find((fam) => fam.id === data);
        const familyName = familyItem?.name;
        matomoTrackEvent('Simulation', 'selectedFilter', familyName, 1);

      },
      getVpCount(family) {
        return this.vigilancePointsListCounter[family] ?? 0;
      }
    },
    watch: {
      'filtersSelected.family': {
        handler() {
          this.filtersSelected.system = null;
          this.filtersSelected.complexity = null;
          window.setTimeout(() => {
            if (this.$refs['filterSystem']) {
              this.$refs['filterSystem'].scrollIntoView({ block: 'start', behavior: 'smooth', inline: "nearest" });
            }
          }, 500);
        },
        deep: true
      },
      'filtersList.system': {
        handler(newVal) {
          if (newVal.length === 1) {
            this.filtersSelected.system = newVal[0].idCombi;
          }
        },
        deep: true
      },
      randomKeyForTansition() {
        window.setTimeout(() => {
          if (this.$refs['results']) {
            this.$refs['results'].scrollIntoView({ block: 'start', behavior: 'smooth', inline: "nearest" });
          }
        }, 500);
      }
    }
  };
</script>
