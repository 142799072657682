<template>
  <fieldset class="legends">
    <legend>Niveaux de vigilance</legend>
    <div class="legend-items">
      <!-- <div class="legend-item vp-item levelNone">
        <label><input type="radio" :value="null" v-model="filtersSelected.complexity">
        Tous
        </label>
      </div> -->
      <div
      v-for="(level, levelIdx) in legendDataSorted"
      :key="levelIdx"
      class="legend-item">
        <span class="level-color" :class="'level' + (level.complexity)"></span>
        <span class="level-name">{{level.name}}:</span>
        <span class="level-description">{{level.description}}</span>
      </div>
    </div>

  </fieldset>
</template>

<script>
export default {
  data () {
    return {
      legendData: [
        {
          complexity: 1,
          name: "Vigilance courante",
          description: "Bonnes pratiques à appliquer rigoureusement."
        },
        {
          complexity: 2,
          name: "Vigilance forte",
          description: "Risque de sous-qualité, risque de coûts supplémentaires lors de travaux ultérieurs, sous-performance des systèmes induisant des surcoûts."
        },
        {
          complexity: 3,
          name: "Vigilance maximale",
          description: "Risques de pathologies du bâtiment, nécessité de déposer et refaire les travaux lors d'améliorations futures, sous-performance et usure accélérée des systèmes."
        },
        {
          complexity: 4,
          name: "Vigilance extrême",
          description: "Danger pour la sécurité des occupants."
        }
      ]
    }
  },
  methods: {
    sortLegendData () {
      return this.legendData.sort((a, b) => {
        return b.complexity - a.complexity
      })
    }
  },
  computed: {
    legendDataSorted () {
      return this.sortLegendData()
    }
  }
}
</script>

<style>

</style>