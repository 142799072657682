<template>

  <div class="card intro-main">
    <div class="card-body intro-content">

      <div class="intro p-3">


        <h1>Vous allez intervenir dans le cadre d'une rénovation énergétique par étapes ?</h1>
        <h2>Attention, vos actions peuvent impacter d'autres lots de travaux ! </h2>
        <img class="img-intro" alt="Image Intro" src="@/assets/imgIntro.png">

        <hr>
        <h3>L'application pour visualiser les points de vigilance d'une rénovation par étapes </h3>
        <p class="lead">Les rénovations énergétiques et environnementales pour être performantes doivent être envisagées de façon globale. Cependant, rénover globalement n'est parfois pas
          envisageable, les
          propriétaires recourent alors à une rénovation énergétique par étape. Attention cette approche par étape nécessite de respecter certains principes et appelle quelques points de vigilance.
        </p>
        <p class="lead">L'application vous accompagne donc au fur et à mesure de vos interventions pour attirer votre attention sur les précautions à prendre avant toute action de rénovation,
          notamment pour mieux
          gérer les interactions entre les lots de travaux qui ne seraient pas traités simultanément.</p>

        <hr>
        <h3>Comment ça marche ?</h3>
        <ol class="lead">
          <li>Renseignez le premier lot sur lequel vous intervenez</li>
          <li>L'outil affiche automatiquement les autres lots qui pourraient être impactés par l'intervention visée. Selon les risques estimés, les points de vigilance vous seront présentés selon
            leurs niveaux de gravité :</li>
        </ol>
        <levels-legend></levels-legend>
      </div>

      <div class="btn-bloc">
        <a class="btn btn-secondary" @click.prevent="showMoreInfo = !showMoreInfo">En savoir plus sur l'outil</a>
        <router-link :to="{ name: 'stepV2', params: { step: 1 } }" class="btn">Démarrer la simulation</router-link>
      </div>

      <modal v-if="showMoreInfo" @close="showMoreInfo = false">
        <h3 slot="header">Rénovation énergétique complète VS rénovation énergétique par étapes : bien comprendre avant de se lancer
        </h3>
        <div slot="body">
          <p>Une rénovation énergétique globale (réalisée en une seule fois) reste le meilleur moyen de mener à bien une rénovation performante. Cela permet de mieux anticiper les interfaces entre les
            différents lots de travaux ainsi que leurs interactions (dimensionnement de la puissance du chauffage en fonction du niveau d'isolation par exemple) et d'obtenir en une seule fois un
            niveau de performance qui n'altère pas les gains futurs. Cependant, rénover intégralement et en une seule fois peut être parfois difficile à envisager pour plusieurs raisons (occupation
            des locaux, planification des travaux, budget, etc.)
          </p>
          <p>La rénovation énergétique « par étapes » est alors possible mais nécessite de respecter certains principes et points de vigilance : il est absolument essentiel de planifier dès le début
            toutes les étapes de travaux qui conduiront à une rénovation complète et cohérente pour obtenir la performance finale du logement. Car certains lots de travaux ne peuvent être envisagés
            indépendamment les uns des autres.</p>

          <p>Il est donc nécessaire de prioriser les étapes et de comprendre les interactions entre lots de travaux (quels impacts sur l'existant ? Quels points de vigilance ? Par quel lot commencer
            ?). C'est ce que l'outil permet de faire.</p>

          <p>Les données de l'outil sont issues de l'expérimentation <a href="https://www.experience-p2e.org/" target="_blank">Expérience P2E</a> et notamment du guide technique « Rénovation des
            maisons individuelles » à destination des maîtres d'œuvre, artisans et particuliers.<br>
            Il a pour premier objectif de vous alerter sur les précautions à prendre lorsque vous intervenez sur un lot de travaux pour comprendre les effets générés sur d'autres lots et les
            précautions à prendre pour ne pas créer de contre-performance, ni de pathologies.
          </p>

          <div style="display:flex; justify-content: space-evenly; flex-wrap:wrap;">
            <a style="margin: 1rem auto; display: block; width: fit-content"
              href="https://lab.cercle-promodul.inef4.org/tool_type/guides-rapports-et-retours-dexperiences/tool/guide-technique-experience-p2e-renovation-des-maisons-individuelles" target="_blank">
              <img src="https://lab.cercle-promodul.inef4.org/storage/uploads/eKuAJeEM4TEvAV6L2GlNywKvAXDmX9XCG8jpTySl.png" style="width:200px; border: 2px solid #ccc" /></a>

            <p>Cercle Promodul / INEF4 est un des membres fondateurs de l'association Expérience P2E : expérimentation qui vise, grâce à la mise en place d'un Passeport Efficacité Énergétique à
              accompagner les particuliers dans leurs projets d'amélioration du logement. Le Passeport Efficacité Energétique (P2E) contribue à la massification de la rénovation des logements, dans le
              but d'amener l'ensemble du parc à un niveau de consommation énergétiquement bas et dans le respect des objectifs de la stratégie nationale bas carbone à l'horizon 2050.</p>
            <a style="margin: 1rem auto; display: inline; width: fit-content" href="https://cercle-promodul.inef4.org" target="_blank">
              <img src="@/assets/logo_fdcp.png" style="width:200px;" />
            </a>
            <a style="margin: 1rem auto; display: inline; width: fit-content" href="https://lab.cercle-promodul.inef4.org" target="_blank">
              <icon-base style="color: #333" class="app-icon-" :width="150" :height="100" view-box="0 0 512 225" icon-name="Le LAB">
                <icon-logo-le-lab />
              </icon-base>
            </a>
          </div>
        </div>
      </modal>


    </div>
  </div>

</template>

<script>
  export default {
    data() {
      return {
        showMoreInfo: false
      };
    }
  };
</script>
