<template>
  <div class="master">

    <header id="header">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-12 col-xxl-3 text-center d-flex flex-column justify-content-center align-items-center">
            <div class="logo-block">
              <div class="logo-deg-wrapper">
                <router-link :to="{ name: 'v2' }">
                  <div class="logo">
                    <div class="logo-svg">
                      <icon-base class="logo-one-house-deg" icon-name="logo">
                        <icon-logo-house-deg></icon-logo-house-deg>
                      </icon-base>
                    </div>
                    <div class="logo-name"><span>Réno</span><span>Vigilance</span></div>

                  </div>
                  <!-- <div class="base-line">Précautions pour une rénovation énergétique par étape</div> -->
                </router-link>
              </div>
              <small class="logo-by-promodul">by LAB PROMODUL</small>
            </div>
          </div>
          <div class="col-12 col-lg-12 col-xxl-7 d-flex flex-column justify-content-center align-items-center">
            <div class="header-title">
              <h2>Précautions pour une rénovation énergétique par étape</h2>
            </div>
          </div>
          <div class="col-12 col-lg-12 col-xxl-2 d-flex align-items-center justify-content-center justify-content-xxl-end">
            <div class="social-bloc">
              <social-network></social-network>
            </div>
          </div>
        </div>
      </div>
    </header>

    <main>
      <router-view />
    </main>

    <app-footer></app-footer>

  </div>
</template>

<script>
  import socialNetworkShareData from "@/json/socialNetworkShareData.json";
  import Footer from "@/components/v2/Footer.vue";
  export default {
    components: {
      AppFooter: Footer
    },
    metaInfo() {
      return {
        title: process.env.VUE_APP_TITLE,
        meta: [
          { name: 'description', content: socialNetworkShareData.description },
          { name: 'keywords', content: socialNetworkShareData.hashtags },

          { property: 'twitter:card', content: "summary_large_image" },
          { property: 'twitter:title', content: socialNetworkShareData.title },
          { property: 'twitter:description', content: socialNetworkShareData.description },
          { property: 'twitter:image', content: process.env.VUE_APP_BASE_URL + "/img/imgShareSocialNetworks.png" },

          { property: 'og:title', content: socialNetworkShareData.title },
          { property: 'og:site_name', content: process.env.VUE_APP_TITLE },
          { property: 'og:description', content: socialNetworkShareData.description },
          { property: 'og:type', content: 'web' },
          { property: 'og:url', content: window.location.href.toString() },
          { property: 'og:image', content: process.env.VUE_APP_BASE_URL + "/img/imgShareSocialNetworks.png" }
        ]
      };
    },
    computed: {
      version() {
        return process.env.VUE_APP_VERSION;
      },
      env() {
        return process.env.VUE_APP_ENV;
      }
    }
  };
</script>
