<template>
<div>
  <!-- <div>lvl : {{level}}</div> -->
  <div class="levels">
    <div class="level level1" :class="{'off': level !== 1}"></div>
    <div class="level level2" :class="{'off': level !== 2}"></div>
    <div class="level level3" :class="{'off': level !== 3}"></div>
    <div class="level level4" :class="{'off': level !== 4}"></div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    level: {
      default: 0
    }
  }

}
</script>
