<template>
  <div>
    <!-- <h3>REMINDER</h3>
    {{lotsTvx.A.familyData}}
    <hr> -->
    


    <div class="reminder-bloc">
      <div class="reminder-icon" @click="$emit('goToStep',1)">
        <lot-tvx :name="family.name" :icon="family.icon"></lot-tvx>
      </div>
      <div class="reminder-wrapper">
        <div class="reminder-title">{{stepById(1).title}}</div>
        <div class="reminder-body">
          <div
          class="reminder-family-name"
          @click="$emit('goToStep',1)">{{family.name}}</div>
          <div
          v-if="system"
          class="reminder-system-name"
          @click="$emit('goToStep',2)">{{system.label}}</div>
        </div>

      </div>
      <div class="reminder-more-info">
        <button @click="showInTheGuide = true" class="btn btn-guide">Plus de détails</button>
        <button @click="showMoreInfo = true" class="btn btn-pacte" v-if="isLinksPacte">Pour aller plus loin</button>
      </div>
    </div>


    <modal v-if="showInTheGuide" @close="showInTheGuide = false">
      <h3 slot="header">Guide Technique - P2E</h3>
      <div slot="body">
        <p style="font-size: 0.85rem">Si le document PDF ne s'affiche pas correctement, <a :href="urlPdfGuide + '#page='+pdfPage" target="_blank">vous pouvez l'ouvrir dans une nouvelle fenêtre.</a></p>
        <iframe :src="urlPdfGuide + '#page='+pdfPage" style="width: 100%; min-height: 300px; height:65vh" embedded="true" type='application/pdf' scrolling="auto" ref="iFrameGuide"></iframe>
      </div>
    </modal>

    <modal v-if="showMoreInfo && isLinksPacte" @close="showMoreInfo = false">
      <h3 slot="header">Pour aller plus loin</h3>
      <div slot="body">
      
      <div class="links-out">
        <template v-if="linksCategoryFilter(1).length > 0">
          <h4>Aide à la mise en oeuvre</h4>
          <div class="link-items">
            <div class="link-item" v-for="(doc, docIdx) in linksCategoryFilter(1)" :key="docIdx">
              <a :href="doc.url" target="_blank"><i class="fas fa-external-link-alt"></i> {{doc.title}}</a>
            </div>
          </div>
        </template>

        <template v-if="linksCategoryFilter(2).length > 0">
          <h4>Pour aller plus loin</h4>
          <div class="link-items">
            
            <div class="link-item" v-for="(doc, docIdx) in linksCategoryFilter(2)" :key="docIdx">
              <a :href="doc.url" target="_blank"><i class="fas fa-external-link-alt"></i> {{doc.title}}</a>
            </div>
          </div>
        </template>
      </div>

      </div>
    </modal>

  </div>
</template>

<script>
import {  mapState, mapGetters } from 'vuex'
import LotTvx from '@/components/v2/LotTvx.vue'
export default {
  components: {
    LotTvx
  },
  data () {
    return {
      showMoreInfo: false,
      showInTheGuide: false,
      dummies: {
        docPdf: [
          {
            id:1,
            title: 'Bardages en acier protégé et en acier inoxydable - Neuf et Rénovation',
            url: 'https://www.programmepacte.fr/bardages-en-acier-protege-et-en-acier-inoxydable-neuf-et-renovation'
          },
          {
            id:2,
            title: 'Isolation en sous-face des planchers bas - Neuf et Rénovation',
            url: 'https://www.programmepacte.fr/isolation-en-sous-face-des-planchers-bas-neuf-et-renovation'
          },
          {
            id:3,
            title: "ITE - Procédés de bardage rapporté à lame d'air ventilée - Neuf et Rénovation",
            url: 'https://www.programmepacte.fr/ite-procedes-de-bardage-rapporte-lame-dair-ventilee-neuf-et-renovation'
          },
        ]
      }
    }
  },
  methods: {
    linksCategoryFilter (category) {
      if (!this.family.links?.pacte) return []
      return this.family.links.pacte.filter((itm) => {
        return itm.category === category
      })
    }
  },
  computed: {
    ...mapState(['lotsTvx', 'steps']),
    ...mapGetters(['stepById']),
    family () {
      return this.lotsTvx.A.familyData
    },
    system () {
      const systems = this.lotsTvx.A.systemData
      return systems.beforeTvx ? systems.beforeTvx : systems.afterTvx ? systems.afterTvx : null
    },
    vueAppEnv () {
      return {
        VUE_APP_VERSION: process.env.VUE_APP_VERSION,
        VUE_APP_ENV: process.env.VUE_APP_ENV,
        VUE_APP_BASE_URL: process.env.VUE_APP_BASE_URL
      }
    },
    urlPdfGuide () {
      return process.env.VUE_APP_HOST + process.env.VUE_APP_BASE_URL + 'guide_P2E.pdf'
    },
    pdfPage () {
      return this.family.links?.guide[0].page
    },
    isLinksPacte () {
      return this.family.links?.pacte && this.family.links.pacte.length > 0
    }
  }
}
</script>

<style>

</style>