export default {
  saveFamilyLot ({commit}, payload) {
    commit('SAVE_FAMILY_LOT', payload)
  },
  saveSystemLot ({commit}, payload) {
    commit('SAVE_SYSTEM_LOT', payload)
  },
  setStepValidation ({commit}, payload) {
    commit('SET_STEP_VALIDATION', payload)
  }
}
