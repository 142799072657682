import appData from "@/json/appData.json";
import p2eAllData from "@/json/p2eAllData.json";
export default {
  effectSelected: 'component-fade',
  isLoading: false,
  renoWorksData: { ...appData, ...p2eAllData },
  currentStep: null,
  steps: [
    {
      id: 1,
      title: "Je travaille sur le lot suivant :",
      isValid: false,
      component: 'step-1',
      lotTvxUsed: 'A',
      values: [
        {
          name: 'lotsTvx.A.familyData',
          type: 'Object',
          // pattern: ???
        }
      ]
    },
    {
      id: 2,
      title: "Préciser :",
      isValid: false,
      component: 'step-2',
      lotTvxUsed: 'A'
    },
    {
      id: 3,
      title: "Liste des points de vigilance",
      isValid: false,
      component: 'step-3',
      lotTvxUsed: 'A'
    },
  ],
  lotSelectedA: null,
  //--------------
  lotDefault: {
    familyData: null,
    systemData: {
      beforeTvx: null,
      afterTvx: null,
    },
  },
  lotsTvx: {
    A: null, // preceding_lot
    B: null, // following_lot
  },
  isUniqueVp: false,
};
