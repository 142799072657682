<template>
  <div>
    <h3>{{ stepTitle }}</h3>
    <div class="lots-block">
      <div class="lot-item" :class="{ 'active': familyData && family.id === familyData.id }" v-for="(family, familyId) in tvxFamiliesStep1" :key="familyId" @click="selectedFamily(family)">
        <lot-tvx :name="family.name" :icon="family.icon"></lot-tvx>
      </div>
    </div>
  </div>

</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import LotTvx from '@/components/v2/LotTvx.vue';
  import { matomoTrackEvent } from '@/utils/matomoTracker.js';

  export default {
    props: {
      lotTvxUsed: {
        required: true
      },
      stepId: {
        required: true
      },
      stepTitle: {
        required: false
      }
    },
    components: {
      LotTvx
    },
    created() {
      this.familyData = this.lotTvxData.familyData;
    },
    data() {
      return {
        familyData: null
      };
    },
    computed: {
      ...mapState(['lotsTvx']),
      ...mapGetters(['tvxFamilies']),
      tvxFamiliesStep1() {
        return this.tvxFamilies.filter((family) => {
          return family.showStep1 === true;
        });
      },
      lotTvxData() {
        if (!this.lotsTvx[this.lotTvxUsed]) {
          console.error(`LotTvx ${this.lotTvxUsed} unknow`);
          return null;
        }
        return this.lotsTvx[this.lotTvxUsed];
      },
    },
    methods: {
      selectedFamily(data) {
        this.familyData = data;

        matomoTrackEvent('Simulation', 'selectedFamily', this.familyData.name, 1);

        window.setTimeout(() => {
          this.$emit("goToNextStep");
        }, 250);
        // this.$emit("goToNextStep")
      },
      save() {
        this.$store.dispatch('saveFamilyLot', { lot: this.lotTvxUsed, value: this.familyData });
        if (this.familyData) {
          this.$store.dispatch('setStepValidation', { stepId: 1, isValid: true });
        }
      }
    },
    watch: {
      familyData() {
        this.save();
        // this.$emit("goToNextStep")
      }
    }

  };
</script>
