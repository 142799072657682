<template>
  <div class="debug">
    <div class="debug__header">
      <div class="debug__title">{{title}}</div>
      <small>Type: {{type}} / Size: {{size}}</small>
    </div>
    <div class="debug__body">
      <pre>{{data}}</pre>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: false,
      default: '~Debug Title~'
    },
    data: {
      required: true,
      default: null
    }
  },
  computed: {
    type() {
      return typeof this.data
    },
    size() {
      return this.data?.length
    }
  }
}
</script>

<style lang="scss">
.debug {
  border: 1px solid darkred;
  margin:1px;
  padding:20px;
  // width: 100%;
  min-width: 300px;
  max-width: 1024px;

  .debug__header {
    // text-transform:uppercase;
    background-color: darkred;
    color: white;
    margin: 3px 0;
    padding: 5px;

    small {
      font-size: 0.8rem;
    }
  }
  .debug__body {
    max-height: 300px;
    overflow: auto;
    border: 2px dotted red;
    background-color: rgb(65, 2, 2);
    color: white;
  }

}
</style>