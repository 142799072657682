<template>
<transition name="modal">
<div class="modal-mask" @click="$emit('close')">
  <div class="modal-wrapper">
    <div class="modal-container" @click.stop="">
      <button class="modal-close-button" @click="$emit('close')">
        <i class="fas fa-times"></i>
      </button>
      <div class="modal-header">
        <slot name="header"></slot>
      </div>

      <div class="modal-body">
        <slot name="body">default body</slot>
      </div>

      <div class="modal-footer">
        <slot name="footer">
          <!-- default footer -->
          <!-- <button class="modal-default-button" @click="$emit('close')">
            Fermer
          </button> -->
        </slot>
      </div>
    </div>
  </div>
</div>
</transition>
</template>

<script>
export default {
  created () {
    console.log('modal created')
    this.setBodyScroll (false)
  },
  beforeDestroy () {
    console.log('modal destroy')
    this.setBodyScroll (true)
  },
  methods: {
    setBodyScroll (bool) {
      if (bool === true) {
        document.body.style.overflow = 'auto'
      } else {
        document.body.style.overflow = 'hidden'
      }
    }
  }

}
</script>

<style>

</style>