<template>
<div class="lot-tvx-item-wrapper">
    <div class="lot-tvx-item">
      <icon-base v-if="icon" class="lot-tvx-icon" :icon-name="name">
        <component :is="icon"></component>
      </icon-base>
      <span class="lot-tvx-text">{{name}}</span>
    </div>
</div>
</template>

<script>

export default {
  props: {
    name: {
      required: true,
      default: "NC"
    },
    icon: {
      required: true,
      default: null
    }
  }
}
</script>
