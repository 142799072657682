<template>
<div>
  <h3>{{stepTitle}}</h3>
  <div class="form-block form-select">
    <!-- isLotTvxPeriod('before') : {{isLotTvxPeriod('before')}} / isLotTvxPeriod('after') : {{isLotTvxPeriod('after')}} -->
    <p class="info">Selon la nature des travaux sur le lot, les points de vigilance et les précautions à adopter sur les autres lots peuvent varier. Apporter ci-dessous la précision souhaitée en lien avec votre intervention.</p>
    <system-data-select
      v-if="isLotTvxPeriod('before')"
      v-model="systemData.beforeTvx"
      :tvx-systems="systemsBefore"
      :tvxPeriod="'before'"
    ></system-data-select>

    <system-data-select
     v-if="isLotTvxPeriod('after')"
      v-model="systemData.afterTvx"
      :tvx-systems="systemsAfter"
      :tvxPeriod="'after'"
    ></system-data-select>

    <button class="form-btn btn btn-primary" @click="systemSelected">
      <i class="fas fa-chevron-circle-right"></i>
    </button>

    <!-- <debug-v2 title="systemData" :data="systemData"></debug-v2> -->
    <!-- <debug-v2 title="systemsBefore" :data="systemsBefore"></debug-v2> -->
    <!-- <debug-v2 title="systemsAfter" :data="systemsAfter"></debug-v2> -->
    <!-- <debug-v2 title="lotTvxData" :data="lotTvxData"></debug-v2> -->
    

  </div>
</div>
  
</template>

<script>
import {  mapState, mapGetters  } from 'vuex'
import SystemDataSelect from '@/components/v2/SystemDataSelect'
export default {
  props: {
    lotTvxUsed: {
      required: true
    },
    stepId: {
      required: true
    },
    stepTitle: {
      required: false
    }
  },
  components: {
    SystemDataSelect
  },
  created() {
    // console.log('CREATED')
    this.init()
    if (this.isNoSystem) {
      this.systemData.afterTvx = {id: null}
      this.systemSelected(0)
    }
  },
  beforeMount () {
    // console.log('beforeMount')
  },
  beforeUpdate(){
    // console.log('UPDATE')
    this.init()
  },
  data() {
    return {
      systemData: {
        beforeTvx: null,
        afterTvx: null
      }
    }
  },
  computed: {
    ...mapState(['lotsTvx']),
    ...mapGetters(
      [
      // 'tvxFamilies',
      'tvxSystemByDbSrc'
      ]
    ),
    lotTvxData () {
      if (!this.lotsTvx[this.lotTvxUsed]) {
        console.error(`LotTvx ${this.lotTvxUsed} unknow`)
        return null
      }
      return this.lotsTvx[this.lotTvxUsed]
    },
    systemsBefore () {
      if (!this.lotTvxData.familyData) return
      return this.tvxSystemByDbSrc(this.lotTvxData.familyData.dbSource)
    },
    systemsAfter () {
      if (!this.systemsBefore) return
      return this.systemsBefore.filter((elm) => {
        return elm.available === undefined || elm.available === 1
      })
    },
    caseStatus () {
      return this.lotTvxUsed === 'A' ? 'casePrecedingLot' :  'caseFollowingLot'
    },
    isNoSystem () {
      return !this.systemsBefore && !this.systemsAfter
    }
    
    
  },
  methods: {
    init() {
      // console.log('INIT')
      // console.log(this.systemData, this.lotTvxData.systemData, this.systemData === this.lotTvxData.systemData)
      this.systemData = this.lotTvxData.systemData
    },
    isLotTvxPeriod (periodTvx = 'before') { // periodTvx: before or after
      if (!this.lotTvxData.familyData) return false
      const showPeriod = periodTvx === 'before' ? 'showBefore' : 'showAfter'
      return this.lotTvxData?.familyData?.vigilanceCondition[this.caseStatus][showPeriod]
    },
    save() {
      this.$store.dispatch('saveSystemLot',{lot: this.lotTvxUsed, value: this.systemData})
      if (this.systemData.beforeTvx || this.systemData.afterTvx) {
        this.$store.dispatch('setStepValidation', {stepId: 2, isValid: true})
      }
    },
    systemSelected(ms) {
      const nbTime = ms !== undefined ? ms : 300
      // console.log('systemSelected', nbTime)
      window.setTimeout(() => {
        this.$emit("goToNextStep")
      }, nbTime)
      // this.$emit("goToNextStep")
    },
  },
  watch: {
    systemData: {
      handler (newValue, oldValue) {
        this.save()
        const testIsValid = (newValue.beforeTvx !== null || newValue.afterTvx !== null)
        this.$store.dispatch('setStepValidation', {stepId: this.stepId, isValid: testIsValid})

        // If a selection has been already done we can send systemSelected()
        // Or it's the inital/first change of the value
        if (oldValue.beforeTvx !== null || oldValue.afterTvx !== null) { // = etat initial
          this.systemSelected()
        }

      },
      deep: true
    }
  }
}
</script>

<style>

</style>