<template>
  <footer class="footer-main">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-3 d-flex align-items-center justify-content-center">
          <div class="footer-img">
            <img src="@/assets/logo_fdcp.png" alt="Responsive image">
          </div>
        </div>
        <div class="col-12 col-md-9 d-flex align-items-center justify-content-center">
          <div class="footer-text">
            <p class="copy-version">©&nbsp;<a href="https://cercle-promodul.inef4.org" target="_blank">Cercle Promodul / INEF4</a>
              - v{{ version }} <span v-if="env === 'development'">[{{ env }}]</span>
            </p>
            <p class="text">Cet outil à vocation pédagogique, et pour l'utilisation du plus grand nombre, a été conçu et développé par le <a href="https://cercle-promodul.inef4.org"
                target="_blank">Fonds
                de Dotation Cercle Promodul / INEF4</a> dans le cadre d'un projet d'action soutenu par le Mécénat. Les données utilisées sont issues des travaux menés par l'association <a
                href="https://www.cercle-promodul.inef4.org/projet/soutien-experience-passeport-efficacite-energetique/" target="_blank">Expérience P2E</a> (Passeport Efficacité Energétique). Son
              utilisation est libre et n'est pas destinée à un usage commercial.
            </p>
          </div>
        </div>
      </div>


    </div>
  </footer>
</template>

<script>
  export default {
    computed: {
      version() {
        return process.env.VUE_APP_VERSION;
      },
      env() {
        return process.env.VUE_APP_ENV;
      }
    }
  };
</script>