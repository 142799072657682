export default {

  SAVE_FAMILY_LOT (state, payload) {
    state.lotsTvx[payload.lot].familyData = payload.value
  },

  SAVE_SYSTEM_LOT (state, payload) {
    state.lotsTvx[payload.lot].systemData = payload.value
  },

  SET_STEP_VALIDATION (state, payload) {
    // console.log('SET_STEP_VALIDATION', payload)
    state.steps.find((step) => {
      return step.id === payload.stepId
    }).isValid = payload.isValid
  }
}
