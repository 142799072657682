import Vue from "vue";
import VueRouter from "vue-router";

import V2Main from "../views/v2/Main.vue";
import V2Intro from "../views/v2/Intro.vue";

Vue.use(VueRouter);

const routes = [
  /**
   * V2
   */
  {
    path: "/",
    name: "v2",
    component: V2Intro,
    meta: { title: "RénoVigilance | Accueil" },
    // redirect: { name: 'stepV2', params: {step: 1} }
    //A mettre en page d'intro
  },
  {
    path: "/:step",
    name: "stepV2",
    meta: { title: "RénoVigilance | Simulation" },
    component: V2Main,
  },
  {
    path: "/*",
    redirect: { name: "v2" },
  },
  //------------------
  {
    path: "*",
    redirect: { name: "v2" },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
