<template>
    <div class="form-block- form-select-">
      <!-- <label>Etat <u>{{tvxPeriodText}}</u></label> -->
      <select v-model="localValue">
        <option :value="null">Sélection...</option>
        <option :value="tvxSystem" v-for="(tvxSystem, tvxSystemIdx) in tvxSystems" :key="tvxSystemIdx">
          {{tvxSystem.label}}
        </option>
      </select>
    </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    tvxSystems: {
      required: true
    },
    tvxPeriod: {
      required: true
    }
  },
  created() {
    this.init()
  },
  beforeUpdate() {
    this.init()
  },
  data() {
    return {
      localValue: null
    }
  },
  computed: {
    tvxPeriodText () {
      return this.tvxPeriod === 'after' ? "APRES TRAVAUX" : "AVANT TRAVAUX"
    }
  },
  methods: {
    init() {
      this.localValue = this.value
    }
  },
  watch: {
    localValue(newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>

<style>

</style>