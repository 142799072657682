export default {
  isLoading (state) {
    return state.isLoading
  },
  //=============================================
  stepById: (state) => (stepId) => {
    return state.steps.find((step) => {
      return step.id === stepId
    })
  },
  //=============================================
  tvxFamilies (state) {
    return state.renoWorksData.tvxFamilies
  },
  tvxFamilyById: (state, getters) => (id) => {
    if (!id) return
    return getters.tvxFamilies.find((itm) => {
      return itm.id.toString() === id.toString()
    })
  },
  tvxFamilyByDbSrc: (state, getters) => (id) => {
    if (!id) return
    return getters.tvxFamilies.find((itm) => {
      return itm.dbSource.toString() === id.toString()
    })
  },
  //=============================================
  tvxSystemByDbSrc: (state) => (dbSrc) => {
    if (!dbSrc) return
    if (!state.renoWorksData[dbSrc]) return
    return state.renoWorksData[dbSrc]
  },
  tvxSystemByTvxFamily: (state) => (family) => {
    if (!family) return
    return state.renoWorksData.tvxFamilies.find((item) => {
      return item.vigilanceCondition.family === family
    })
  },
  //=============================================
  vigilancePoints (state) {
    return state.renoWorksData.vigilance_points
  },
  vigilancePointById: (state, getters) => (id) => {
    if (!id) return
    return getters.vigilancePoints.find((itm) => {
      return itm.id.toString() === id.toString()
    })
  },
  //=============================================
  vigilancePointConditions (state) {
    return state.renoWorksData.vigilance_point_conditions 
  },
  vigilancePointConditionById: (state, getters) => (id) => {
    if (!id) return
    return getters.vigilancePointConditions.find((itm) => {
      return itm.id.toString() === id.toString()
    })
  },
  vigilancePointConditionByPrecedingLot: (state, getters) => (
    {
      preceding_lot_type,
      preceding_lot_attr,
      preceding_lot_value_before = null,
      preceding_lot_value_after = null
    } = {}
  ) => {
    let conditionPrecedingLot = preceding_lot_type
    conditionPrecedingLot += preceding_lot_attr ? '.' + preceding_lot_attr : ''
    // console.log(
    //   `%cvigilancePointConditionByPrecedingLot:\n%cpreceding_lot: ${conditionPrecedingLot},\npreceding_lot_value_before: ${preceding_lot_value_before},\npreceding_lot_value_after: ${preceding_lot_value_after}`,
    //   'font-size: 1rem; color: pink; text-decoration: underline',
    //   'color: pink'
    // );


    const test = getters.vigilancePointConditions.filter((itm) => {

      if(itm.preceding_lot === conditionPrecedingLot) {
        // console.log('ITEM', itm)

        if (preceding_lot_value_before === null && preceding_lot_value_after === null) {
          return true
        }

        if (itm.preceding_lot_value_before === null && itm.preceding_lot_value_after === null) {
          return true
        }
        if (itm.preceding_lot_value_before === preceding_lot_value_before && itm.preceding_lot_value_after === null) {
          return true
        }
        if (itm.preceding_lot_value_before === null && itm.preceding_lot_value_after === preceding_lot_value_after) {
          return true
        }

        return false

        // return ((preceding_lot_value_before=== null && preceding_lot_value_after === null) || (itm.preceding_lot_value_before === null && itm.preceding_lot_value_after === null) || (itm.preceding_lot_value_before === preceding_lot_value_before && itm.preceding_lot_value_after === null) || (itm.preceding_lot_value_before === null && itm.preceding_lot_value_after === preceding_lot_value_after))
      }
    })
    // console.log('TEST', test)
    return test
  },
  vigilancePointForPrecedingLot: (state, getters) => (
    {
      preceding_lot_type,
      preceding_lot_attr,
      preceding_lot_value_before = null,
      preceding_lot_value_after = null,
      is_unique = false} = {}
    ) => {
    // console.log(
    //   "vigilancePointForPrecedingLot",
    //   preceding_lot_type,
    //   preceding_lot_attr,
    //   preceding_lot_value_before,
    //   preceding_lot_value_after,
    //   is_unique
    // );
    let vigilancePoints = []
    let idVigilancePointMemory = []
    const vigilancePointConditions= getters.vigilancePointConditionByPrecedingLot(
        {
          preceding_lot_type,
          preceding_lot_attr,
          preceding_lot_value_before,
          preceding_lot_value_after,
        }
      )

    vigilancePointConditions.forEach((condition) => {
      if (idVigilancePointMemory.indexOf(condition.vigilance_point_id) > -1 &&  is_unique === true) return 
      const temp = condition.following_lot.split('.')
      const family = temp[0]
      // console.log('%c' + family, 'color: aqua')
      // console.log('%c' + condition.following_lot_value_before, 'color: aqua')
      // console.log('%c' + condition.following_lot_value_after, 'color: aqua')
      // const attr = temp[1]
      const vigilancePoint = getters.vigilancePointById(condition.vigilance_point_id)

      const familyData = getters.tvxSystemByTvxFamily(family)
      // console.log('familyData', familyData)

      const familySystems = getters.tvxSystemByDbSrc(familyData?.dbSource) || []
      // console.log('familySystems', familySystems)

      const systemData = familySystems.find((system) => {
        // return  (condition.following_lot_value_before === null && condition.following_lot_value_after)
        //         || system.id === condition.following_lot_value_before
        //         || system.id === condition.following_lot_value_after

        return (system.id === condition.following_lot_value_before || system.id === condition.following_lot_value_after)
      })
      if (systemData) {
        systemData.family = family
        systemData.idCombi = family + '-' + systemData.id
        // console.log('systemData', systemData)
      }
      

      let vp = {
        ...vigilancePoint,
        condition,
        family,
        familyData,
        systemData
      }
      vigilancePoints.push(vp)
      // vigilancePoint['condition'] = itm
      // vigilancePoints[family].push(vigilancePoint)
      idVigilancePointMemory.push(condition.vigilance_point_id)

    })
    return vigilancePoints
  }


}